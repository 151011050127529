﻿export const SUCCESS_ADD_CART_MESSAGE = 'Item has been added to cart!';

export const FAIL_ADD_CART_MESSAGE = 'Item has been failed added to cart!';

export const SUCCESS_ADD_MESSAGE = 'Item has been added to cart!';

export const CHOOSE_VARIANT_MESSAGE = 'Please choose a variant!';

export const STOCK_EMPTY_VARIANT_MESSAGE = 'Sorry this product is out of stock!';

// wishlists
export const $WISHLIST_SUBMIT_BUTTON = () => $('[data-action="add-wishlist"]');

export const $WISHLIST_REMOVE_BUTTON = () => $('[data-action="remove-wishlist"]');

export const ADD_WISHLIST_URL = '/umbraco/surface/member/addwishlist';

export const REMOVE_WISHLIST_URL = '/umbraco/surface/member/removewishlist';

export const SUCCESS_ADD_WISHLIST_MESSAGE = 'The product is now on your wishlist!';

export const SUCCESS_REMOVE_WISHLIST_MESSAGE = 'The product is removed from your wishlist!';

export const ADDRESS_NOT_CHOSEN_MESSAGE = 'Please choose an address!';

export const $DELIVERY_COURIER_NAME_INPUT = $('#courier-name-input');

export const $DELIVERY_COURIER_SERVICE_INPUT = $('#courier-service-input');

export const $DELIVERY_COURIER_COST_INPUT = $('#courier-cost-input');

export const $DELIVERY_COURIER_RADIO = $('[data-identity="delivery-courier-radio"]');

export const $DELIVERY_COURIER_FORM = $('#add-delivery-courier-form');

export const $DELIVERY_COURIER_SUBMIT_BUTTON = $('[data-identity="delivery-courier-submit-button"]');

export const DELIVERY_COURIER_ERROR_MESSAGE = 'There has been an error submitting your delivery courier. Try again!';

export const $PAYMENT_RADIO = $('[data-identity="payment-radio"]');

export const $PAYMENT_BANK_INPUT = $('#bank-input');

export const $PAYMENT_TYPE_INPUT = $('#payment-type-input')

export const $PAYMENT_AND_NOTE_FORM = $('#payment-and-note-form');

export const $PAYMENT_AND_NOTE_SUBMIT_BUTTON = $('#payment-and-note-submit-button');

export const PAYMENT_AND_NOTE_ERROR_MESSAGE = 'There has been an error submitting your payment choice. Try again!';

export const $CHECKOUT_BUTTON = $('[data-action="checkout"]')

export const GET_ALL_PROMO_URL = '/umbraco/surface/cart/getalldiscounts';

export const $AUTOMATIC_PROMOS_SUBMIT_BUTTON = $('[data-action="submit-automatic-promos"]');

export const $PROMO_LOADER = $('.promo-loader');

export const $PROMO_MODAL = $('#promo-modal');

export const $REMOVE_CART_PROMO = () => $('[data-action="remove-cart-promo"]');

export const $REMOVE_PRODUCT_PROMO = () => $('[data-action="remove-product-promo"]');

export const $REMOVE_SHIPPING_PROMO = () => $('[data-action="remove-shipping-promo"]');

export const $REMOVE_COUPON_CODE = () => $('[data-action="remove-coupon-code"]');

export const $REMOVE_MEMBERSHIP_POINT = () => $('[data-action="remove-membership-point-discount"]');

export const COMPLETE_TRANSACTION_URL = '/umbraco/surface/cart/checkoutajax';

export const $COMPLETE_TRANSACTION_BUTTON = () => $('[data-action="complete-transaction"]');

export const $PAY_WITH_MIDTRANS_BUTTON = () => $('[data-action="pay-with-midtrans"]');

export const $PAY_WITH_PAYPAL_BUTTON = () => $('[data-action="pay-with-paypal"]');

export const PAYPAL_BUTTON_CONTAINER_SELECTOR = '[data-identity="paypal-button-container"]';

export const ADD_TO_CART_URL = '/umbraco/surface/cart/addcart';

// Element that show total number
// of items in the cart
export const $CART_QUANTITY_ELEMENTS = () => $('[data-identity="cart-quantity"]');

// Container of cart
export const $CART_ITEM_CONTAINER = () => $('[data-identity="cart-item-container"]');


// Container of side cart
export const $SIDECART_ITEM_CONTAINER = () => $('#bw-sidecart-item-container');


// Element that act as a delete
// cart item button
export const $CART_ITEM_DELETE_ACTION_ELEMENT = () => $('[data-action="delete-cart-item"]');


// Input that contain certain
// cart item quantity
export const $CART_ITEM_QUANTITY_INPUT = () => $('input[data-identity="cart-item-qty"]');

export const UPDATE_CART_ITEM_URL = '/umbraco/surface/cart/updatecartitemquantity';

export const REMOVE_CART_ITEM_URL = '/umbraco/surface/cart/removecartitem';


// All cart item element
export const $CART_ITEMS = () => $(`[data-identity="cart-item"]`);

// cart item element
export const $CART_ITEM_ELEMENT_BY_ID = (id) => $(`[data-identity="cart-item"][data-id="${id}"]`);

export const $CART_ITEM_PRICE = () => $('[data-identity="cart-item-price"]');

export const $SIDE_CART_ITEM_PRICE = () => $('[data-identity="side-cart-item-price"]');

export const $CART_ITEM_QTY = () => $('[data-identity="cart-item-qty"]');

export const $SIDE_CART_ITEM_QTY = () => $('[data-identity="side-cart-item-qty"]');

export const $CART_ITEM_SUBTOTAL = () => $(`[data-identity="cart-subtotal"]`);

export const $SINGLE_CART_ITEM_SUBTOTAL = () => $(`[data-identity="cart-item-subtotal"]`);

export const $CART_CLASSIFICATION_PRICE = () => $(`[data-identity="cart-classification-price"]`);

// checking whether
// variant is available
export function IsVariantAvailable(variant) {

    let isAvailable = variant.IsAvailable;

    let isInStock = variant.Stock > 0;

    let isTrackingStock = variant.IsStockTracking;

    let isIgnoringStock = variant.IsIgnoredStock;

    if (!isAvailable) {
        return false;
    }
    else if (!isInStock && isTrackingStock && !isIgnoringStock) {
        return false;
    }
    else return true;
}