﻿'use strict';

import numeral from 'numeral';

import Cookies from "js-cookie";

export const EMPTY_SIDECART_ELEMENT_STRING = `<div class="bw-empty-cart">
    <p> You don't have any items in your cart.</p>
                </div>`;

export const EMPTY_CART_ELEMENT_STRING = `<tr>
                    <td><p>Your cart is empty. <a style="color: #000; text-decoration: underline" href="/products">Click here to start shopping!</a></p>
                    </td>
                </tr>`;
var symbol = Cookies.get('__currencySymbol');

export const CART_ITEM_ELEMENT_STRING = (cartItem) => {
    let stringToReturn = `<div class="bw-isla-header-cart__item" style="position: relative; overflow: hidden;" data-identity="cart-item" data-id="${cartItem.ID}" data-variant-id="${cartItem.VariantID}">
        <div class="bw-isla-header-cart__item--img" style="background: url(${cartItem.ImageUrl});" data-id="${cartItem.ID}" data-identity="cart-item-image" data-image-url="${cartItem.ImageUrl}"></div>
        <div class="bw-isla-header-cart__item--text d-flex justify-content-between">
            <div class="bw-isla-cart-text">
                <p class="bw-isla-cart-text__title" data-id="${cartItem.ID}" data-identity="cart-item-name" data-name="${cartItem.Variant.ProductName}">${cartItem.Variant.ProductName}</p>`;

    if (cartItem.Variant.Option1 != null && cartItem.Variant.Option1 != undefined) {
        stringToReturn += `<p class="bw-isla-cart-text__size">Size: ${cartItem.Option1}</p>`
    }

    if (cartItem.Variant.Option2 != null && cartItem.Variant.Option2 != undefined) {
        stringToReturn += `<p class="bw-isla-cart-text__size">Color: ${cartItem.Option2}</p>`
    }

    if (cartItem.Variant.Option3 != null && cartItem.Variant.Option2 != undefined) {
        stringToReturn += `<p class="bw-isla-cart-text__size">Material: ${cartItem.Option3}</p>`
    }

    stringToReturn += `<div>
                        <label>Qty: </label>
                        <span data-identity="side-cart-item-qty" data-qty="${cartItem.Quantity}">${cartItem.Quantity}</span>
                       </div>`;

    stringToReturn += `</div>
                                <p class="bw-isla-cart-text2__price"><br><span class="bw-cart-item-price" data-identity="side-cart-item-price" data-price="${cartItem.Price}">${symbol} ${ cartItem.PriceMask }</span ></p >
                            </div>
                        </div>
                    </div>`;

    return stringToReturn;
};

export function UPDATE_PRICE_VIEW_FROM_VARIANT(variant, $elemPrice, $elemDiscount) {

    $elemPrice.text(variant.PriceString);

    if (!variant.IsHideComparePrice && variant.CompareAtPrice > 0) {

        $elemDiscount.text(variant.CompareAtPriceString);

    }
    else {
        $elemDiscount.text('');
    }

}


