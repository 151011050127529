﻿'use strict';

import numeral from 'numeral';

import Cookies from "js-cookie";

import { AjaxRequestData } from '../../global-function';

var symbol = Cookies.get('__currencySymbol');

const $RADIO_CLASSIFICATION = () => $('[data-identity="product-classification"]');

const $SELECTED_CLASSIFICATION_ROW = () => $('tr[data-identity="selected-classification"]');

const $SUMMARY_TOTAL = () => $('td[data-identity="summary-total"]');

const $CALCULATE_PRICE_URL = '/umbraco/surface/printingclassification/calculateprice';


const $ProductPrice = () => $('input#hfPrice');

export const classificationList = [];

$RADIO_CLASSIFICATION().change(function () {

    let dataType = $(this).data('type');

    let classificationId = $(this).data('id');
    let classificationName = dataType === 2 ? $(this).val() : $(this).data('name');
    let classificationPrice = dataType === 2 ? $(this).val() : $(this).data('price');
    let categoryId = $(this).attr('name');
    let productId = $(this).data('productid');
    let price = Number(classificationPrice.replace(/[^0-9.-]+/g, ""));


    // on change radio update data on summary
    $SELECTED_CLASSIFICATION_ROW().each(function () {
        let selectedCategoryId = $(this).data('categoryId');

        if (categoryId === selectedCategoryId) {

            $(this).find('td[data-identity="summary-name"]').html(classificationName);

            $(this).find('td[data-identity="summary-price"]').html(symbol + " " + classificationPrice);

            $(this).data('classificationId', classificationId);

            $(this).data('classificationName', classificationName);

            $(this).data('classificationPrice', classificationPrice);

        }

    });


    let classification = {
        Id: classificationId,
        CategoryId: categoryId,
        ProductId: productId,
        Quantity: 1,
        AdditionalPrice: price,
        InvoiceItemId: "",
        Name: classificationName,
        CategoryName: ""
    };

    AddClassification(classification);
});

//set data on summary if material checked default
$RADIO_CLASSIFICATION().each(function () {

    let dataType = $(this).data('type');

    let classificationId = $(this).data('id');
    let classificationName = $(this).data('name');
    let classificationPrice = $(this).data('price');
    let categoryId = $(this).attr('name');
    let productId = $(this).data('productid');
    let price = Number(classificationPrice.replace(/[^0-9.-]+/g, ""));


    $SELECTED_CLASSIFICATION_ROW().each(function () {

        let selectedCategoryId = $(this).data('categoryId');

        if (categoryId === selectedCategoryId) {
           
            //$(this).find('td[data-identity="summary-name"]').html(classificationName);

            //$(this).find('td[data-identity="summary-price"]').html(symbol + " " + classificationPrice);

            //$(this).data('classificationId', classificationId);

            //$(this).data('classificationName', classificationName);

            //$(this).data('classificationPrice', classificationPrice);

        }

        

    });


    //if ($(this).is(":visible")) {

    //    let classification = {
    //        Id: classificationId,
    //        CategoryId: categoryId,
    //        ProductId: productId,
    //        Quantity: 1,
    //        AdditionalPrice: price,
    //        InvoiceItemId: "",
    //        Name: classificationName,
    //        CategoryName: ""
    //    };

    //    AddClassification(classification);
    //}

});

function AddClassification(classification) {
    console.log('add classification');

    if (classificationList === undefined) {
        classificationList = [];
    }


    
    //value.AdditionalPrice = ; 

    let total_price = 0;

    if (classification.Id) {
        var index = classificationList.findIndex(function (e) {
            return e.CategoryId === classification.CategoryId;
        });

        if (index > -1) {
            classificationList.splice(index,1);
        }

        classificationList.push(classification);
    }

    $.each(classificationList, function (index, value) {

        //check Price
        var param = {
            classificationId: value.Id,
            productId: value.ProductId,
            list: classificationList
        };

        let CalculatePrice = AjaxRequestData($CALCULATE_PRICE_URL, param);

        total_price += CalculatePrice !== undefined ? CalculatePrice.data : 0;
    });

 
    $SUMMARY_TOTAL().html(symbol + " " + numeral(parseFloat($ProductPrice().val()) + total_price).format('0,0') );

    $SUMMARY_TOTAL().each(function () {
        $(this).attr('data-total', total_price);
    });
    
    
}

//get classification data on summary
export function getClassification() {

    let classificationToCart = {
        ClassificationList: classificationList,
        Total: $SUMMARY_TOTAL().attr('data-total')
    };

    return classificationToCart;
}